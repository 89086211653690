import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule, Routes, UrlHandlingStrategy } from '@angular/router';
import { PageNotFoundComponent } from './layout/modules/page-not-found/page-not-found.component';
import { GeneratePopupUrlService } from './layout/modules/popup/services/generate-popup-url.service';

@Component({
    template: '',
})
class EmptyComponent implements OnInit, OnDestroy {
    protected timeoutId?: NodeJS.Timeout;
    constructor(protected router: Router) {}
    ngOnInit(): void {
        const browserLocation = document.location.pathname;

        if (browserLocation.indexOf('/temp-empty') === -1) {
            /*
               When transitioning from AngularJS to Angular using history.pushState({}, null, "/sd/x/y"), the browser would remain stuck
               in this component, even though the URL in the browser was updated to the correct route.

               This workaround notifies the Angular router of the new route specifically in this case.

               Once all AngularJS components are removed, this code can be safely deleted.
            */
            this.timeoutId = setTimeout(() => {
                console.log(`Navigating to ${browserLocation}`);
                this.router.navigate([browserLocation]);
            }, 150);
        }
    }

    ngOnDestroy() {
        clearTimeout(this.timeoutId);
    }
}

const routes: Routes = [
    { path: 'temp-empty', component: EmptyComponent }, // Don't delete (used for angular2 vs angular1 navigation)
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        providers: [{ provide: GeneratePopupUrlService, useFactory: () => new GeneratePopupUrlService(null) }],
    },
    {
        path: 'translate',
        providers: [{ provide: GeneratePopupUrlService, useFactory: () => new GeneratePopupUrlService(1) }],
        loadChildren: () => import('./core/modules/translations/translations.module').then(m => m.TranslationsModule),
    },
    {
        path: 'sd',
        providers: [{ provide: GeneratePopupUrlService, useFactory: () => new GeneratePopupUrlService(null) }],
        loadChildren: () => import('./schooldrive/schooldrive.module').then(m => m.SchooldriveModule),
    },
    {
        path: 'crm',
        providers: [{ provide: GeneratePopupUrlService, useFactory: () => new GeneratePopupUrlService(null) }],
        loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule),
    },
    {
        path: 'financial',
        loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule),
    },
    {
        path: 'create-account',
        loadChildren: () =>
            import('./core/modules/auth/modules/create-account/create-account.module').then(m => m.CreateAccountModule),
    },
    {
        path: 'sign-in',
        loadChildren: () => import('./core/modules/auth/modules/sign-in/sign-in.module').then(m => m.SignInModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./core/modules/auth/modules/forgot-password/forgot-password.module').then(
                m => m.ForgotPasswordModule,
            ),
    },
    {
        path: 'verify-email/:email',
        loadChildren: () => import('./core/modules/auth/modules/verify/verify.module').then(m => m.VerifyModule),
        data: {
            type: 'email',
        },
    },
    {
        path: 'verify-phone/:phone',
        loadChildren: () => import('./core/modules/auth/modules/verify/verify.module').then(m => m.VerifyModule),
        data: {
            type: 'phone',
        },
    },
    {
        path: 'unauthorized/:expectedPermission',
        loadChildren: () =>
            import('./core/modules/auth/modules/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
    },
    {
        path: 'tenant',
        loadChildren: () => import('./core/modules/tenant/tenant.module').then(m => m.TenantModule),
    },
    {
        path: 'user',
        loadChildren: () => import('./core/modules/user/user.module').then(m => m.UserModule),
    },
    { path: '**', component: PageNotFoundComponent },
];

const generatePopupRoutes = () => {
    const outletNumber = 10;

    const paths = ['home', 'home/manage', 'sd', 'financial', 'crm', 'translate', 'tenant', 'user'];
    for (let i = 1; i <= outletNumber; i++) {
        paths.forEach(path => {
            const route = routes.find(r => r.path === path && !r.outlet);
            if (route) {
                const newRoute = {
                    ...route,
                    outlet: `p${i}`,
                    providers: [{ provide: GeneratePopupUrlService, useFactory: () => new GeneratePopupUrlService(i) }],
                };
                routes.push(newRoute);
            }
        });
    }
};

generatePopupRoutes();

export class Ng1Ng2UrlHandlingStrategy implements UrlHandlingStrategy {
    shouldProcessUrl(urlObj) {
        const url = urlObj.toString();
        const isNewAngularRoute = !url.startsWith('/sd1');
        console.log(`Ng1Ng2UrlHandlingStrategy - should new angular process the url?`, { url, isNewAngularRoute });
        return isNewAngularRoute;
    }
    extract(url) {
        return url;
    }
    merge(url) {
        return url;
    }
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            enableTracing: false,
            scrollPositionRestoration: 'enabled',
            urlUpdateStrategy: 'eager',
        }),
    ],
    exports: [RouterModule],
    providers: [{ provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy }],
})
export class AppRoutingModule {}
