import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MenuItemsByModule, MenuService } from '../../services/menu.service';
import { CookieService } from '@shared/cookie.service';
import { MenuToggleService } from '../../services/menu-toggle.service';
import { ThemeService } from '../../services/theme.service';
import { GSearchService } from '../../../g-search/services/g-search.service';
import { FavoritesService } from '../../../core/modules/user-pref/services/favorites.service';
import { ObjectUtilsService } from '@shared/object-utils.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { LayoutService } from '../../services/layout.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideMenuComponent implements OnInit, AfterViewInit {
    @ViewChild(NgScrollbar) ngScrollbar!: NgScrollbar;

    readonly maxQttOfMenusToInitExpanded = 20;
    panelOpenState = false;
    isToggled = false;
    shouldDisplayMenu = false;

    firstPageIsNg1Link = false;
    firstPageUrl: string | null = null;
    modules: string[] = [];
    emptyModule: { [key: string]: boolean } = {};
    menuItemsByModule: MenuItemsByModule = {};
    initWithMenusExpanded: boolean | undefined;
    asideMenuWidth: number | undefined;

    constructor(
        protected cookieUtilsService: CookieService,
        protected menuService: MenuService,
        protected menuToggleService: MenuToggleService,
        protected themeService: ThemeService,
        protected router: Router,
        public gSearchService: GSearchService,
        private changeDetector: ChangeDetectorRef,
        private favoritesService: FavoritesService,
        public objectUtils: ObjectUtilsService,
        public layoutService: LayoutService,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.layoutService.getComponentWidth$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(width => {
            this.asideMenuWidth = width;
            this.changeDetector.detectChanges();
        });

        this.menuService.loadRoutes();
        this.applyRoutes().then();
        this.favoritesService.getFavorites().subscribe(() => this.applyRoutes());
        this.menuService.menusByModule.subscribe(() => this.applyRoutes());
        this.menuToggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
            this.changeDetector.detectChanges();
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const element = document.querySelector(`a[href="${window.location.pathname}"]`);
            if (element) {
                this.ngScrollbar.scrollToElement(element as HTMLElement, { top: -50 }).then();
            }
        }, 500);
    }

    async applyRoutes() {
        this.menuItemsByModule = this.objectUtils.deepCopy(this.menuService.menusByModule.value);
        this.initWithMenusExpanded = this._setInitWithMenusExpanded();
        this.modules = Object.keys(this.menuItemsByModule);
        this.gSearchService.isMenuRendering$.subscribe(status => {
            this.shouldDisplayMenu = status;
            this.changeDetector.detectChanges();
        });

        this.modules.forEach(module => {
            this.emptyModule[module] = this.menuItemsByModule[module].some(z => {
                const children = z.children;
                if (!children && !z.url) return false;
                return !!z.url || !!children?.length;
            });
        });

        const firstPageUrl = await this.menuService.getFirstPageUrl();
        this.firstPageUrl = firstPageUrl ?? null;
    }

    private _setInitWithMenusExpanded(): boolean {
        let qttMenus = 1;
        Object.values(this.menuItemsByModule).forEach(menuItems => {
            menuItems.forEach(menuItem => {
                qttMenus++;

                if (menuItem.children) {
                    qttMenus += menuItem.children.length;
                }
            });
        });

        return qttMenus < this.maxQttOfMenusToInitExpanded;
    }

    toggle() {
        this.menuToggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    closeMenu() {
        this.gSearchService.close();
    }

    trackByModule($index: number, module: string) {
        return module;
    }

    trackByMenu($index: number, menu: MenuItem) {
        return menu.label;
    }

    moduleDisplayName(module: string) {
        if (module === 'core') {
            return false;
        }

        let qttModules = 0;
        Object.keys(this.emptyModule).forEach(module => {
            if (module === 'core') return;
            if (this.emptyModule[module]) qttModules++;
        });

        return this.emptyModule[module] && qttModules > 1;
    }
}
