import { AfterViewInit, Component, DestroyRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MenuItem, MenuItemsByModule, MenuModule, MenuService } from '../../../../services/menu.service';
import { ResponsiveNavigationService } from '../../services/responsive-navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatDrawerContainer } from '@angular/material/sidenav';

@Component({
    selector: 'app-responsive-navigation-modules',
    standalone: false,
    templateUrl: './responsive-navigation-modules.component.html',
    styleUrl: './responsive-navigation-modules.component.scss',
})
export class ResponsiveNavigationModulesComponent implements OnInit, AfterViewInit {
    @ViewChild(NgScrollbar) ngScrollbar!: NgScrollbar;
    @ViewChild('drawerTemplate') drawerTemplate!: TemplateRef<ResponsiveNavigationModulesComponent>;

    modulesByModuleKey: { [key: string]: MenuModule } = {};
    moduleKeys: Array<string> = [];
    menusByModule: MenuItemsByModule = {};
    notificationsByModule: { [key: string]: number } = {};
    expandedModule: { [key: string]: boolean } = {};

    constructor(
        public menuService: MenuService,
        public rns: ResponsiveNavigationService,
        public matDrawerContainer: MatDrawerContainer,
        public destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        // this.matDrawerContainer..subscribe(status => {
        //     if (status) {
        //         setTimeout(() => {
        //             const element = document.querySelector(`a[href="${window.location.pathname}"]`);
        //             if (element) {
        //                 this.ngScrollbar.scrollToElement(element as HTMLElement).then()
        //             }
        //         }, 500);
        //     }
        // });

        this.rns.currentMenu$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(menu => {
            Object.keys(this.expandedModule).forEach(key => (this.expandedModule[key] = false));
            if (menu) {
                const module = this.menuService.inChild(menu);
                if (module) {
                    this.expandedModule[module] = true;
                }
            }
        });

        this.menuService.menusByModule.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(menus => {
            this.menusByModule = menus;
            this.moduleKeys = Object.keys(menus);

            this.modulesByModuleKey = {};
            const modules = this.menuService.modules.value;
            this.moduleKeys.forEach(key => {
                const sdKey = key === 'schooldrive' ? 'sd' : key;
                const module = modules.find(module => module.url === key || module.url === sdKey);
                if (module) {
                    this.modulesByModuleKey[key] = module;
                }
            });

            this.notificationsByModule = {};
            const menusByModule = this.menuService.menusByModule.value;
            Object.keys(menusByModule).forEach(key => {
                if (key === 'core') return;
                this.notificationsByModule[key] = this.getChildrenNotifications(menusByModule[key]);
            });
        });
    }

    ngAfterViewInit() {
        this.rns.setResponsiveNavigationModulesTemplate(this.drawerTemplate);
    }

    getChildrenNotifications(children: Array<MenuItem>): number {
        let totalNotifications = 0;

        children.forEach(child => {
            if (child.notifications && !child.isNav) {
                totalNotifications += child.notifications;
            }

            if (child.children && child.children.length > 0) {
                totalNotifications += this.getChildrenNotifications(child.children);
            }
        });

        return totalNotifications;
    }
}
