// This file is generated automatically after all Permission Seeders were executed
export default {
    core: {
        dashboards: {
            home: {
                access: 'v2permissions.core.dashboards.home.access',
                manageOwnViews: 'v2permissions.core.dashboards.home.manageOwnViews',
                manageSharedViews: 'v2permissions.core.dashboards.home.manageSharedViews',
            },
            widgets: {
                activeSessions: {
                    allUsers: 'v2permissions.core.dashboards.widgets.activeSessions.allUsers',
                    currentUser: 'v2permissions.core.dashboards.widgets.activeSessions.currentUser',
                },
            },
        },
        translations: {
            listRead: 'v2permissions.core.translations.listRead',
            write: 'v2permissions.core.translations.write',
        },
    },
    schooldrive: {
        calendar: {
            access: 'v2permissions.schooldrive.calendar.access',
            frontOffice: {
                coursesOfAnyUser: 'v2permissions.schooldrive.calendar.frontOffice.coursesOfAnyUser',
            },
        },
        dashboards: {
            home: {
                access: 'v2permissions.schooldrive.dashboards.home.access',
                manageOwnViews: 'v2permissions.schooldrive.dashboards.home.manageOwnViews',
                manageSharedViews: 'v2permissions.schooldrive.dashboards.home.manageSharedViews',
            },
        },
        digitalMarketing: {
            access: 'v2permissions.schooldrive.digitalMarketing.access',
            dashboard: {
                access: 'v2permissions.schooldrive.digitalMarketing.dashboard.access',
            },
            googleADS: {
                access: 'v2permissions.schooldrive.digitalMarketing.googleADS.access',
                essr: 'v2permissions.schooldrive.digitalMarketing.googleADS.essr',
                gsds: 'v2permissions.schooldrive.digitalMarketing.googleADS.gsds',
                gstf: 'v2permissions.schooldrive.digitalMarketing.googleADS.gstf',
                permismoinscher: 'v2permissions.schooldrive.digitalMarketing.googleADS.permismoinscher',
                read: 'v2permissions.schooldrive.digitalMarketing.googleADS.read',
                write: 'v2permissions.schooldrive.digitalMarketing.googleADS.write',
            },
        },
    },
};
