// This file is generated automatically after all Translation Seeders were executed
export default {
    commons: {
        actions: {
            apply: {
                label: 'v2translations.commons.actions.apply.label',
            },
            back: {
                label: 'v2translations.commons.actions.back.label',
            },
            cancel: {
                label: 'v2translations.commons.actions.cancel.label',
            },
            close: {
                label: 'v2translations.commons.actions.close.label',
            },
            continue: {
                label: 'v2translations.commons.actions.continue.label',
            },
            discardChanges: {
                label: 'v2translations.commons.actions.discardChanges.label',
            },
            download: {
                label: 'v2translations.commons.actions.download.label',
            },
            keepEditing: {
                label: 'v2translations.commons.actions.keepEditing.label',
            },
            next: {
                label: 'v2translations.commons.actions.next.label',
            },
            readyToSave: {
                label: 'v2translations.commons.actions.readyToSave.label',
            },
            reset: {
                label: 'v2translations.commons.actions.reset.label',
            },
            save: {
                label: 'v2translations.commons.actions.save.label',
            },
        },
        enums: {
            country: {
                ad: 'v2translations.commons.enums.country.ad',
                ae: 'v2translations.commons.enums.country.ae',
                af: 'v2translations.commons.enums.country.af',
                ag: 'v2translations.commons.enums.country.ag',
                ai: 'v2translations.commons.enums.country.ai',
                al: 'v2translations.commons.enums.country.al',
                am: 'v2translations.commons.enums.country.am',
                ao: 'v2translations.commons.enums.country.ao',
                aq: 'v2translations.commons.enums.country.aq',
                ar: 'v2translations.commons.enums.country.ar',
                as: 'v2translations.commons.enums.country.as',
                at: 'v2translations.commons.enums.country.at',
                au: 'v2translations.commons.enums.country.au',
                aw: 'v2translations.commons.enums.country.aw',
                ax: 'v2translations.commons.enums.country.ax',
                az: 'v2translations.commons.enums.country.az',
                ba: 'v2translations.commons.enums.country.ba',
                bb: 'v2translations.commons.enums.country.bb',
                bd: 'v2translations.commons.enums.country.bd',
                be: 'v2translations.commons.enums.country.be',
                bf: 'v2translations.commons.enums.country.bf',
                bg: 'v2translations.commons.enums.country.bg',
                bh: 'v2translations.commons.enums.country.bh',
                bi: 'v2translations.commons.enums.country.bi',
                bj: 'v2translations.commons.enums.country.bj',
                bl: 'v2translations.commons.enums.country.bl',
                bm: 'v2translations.commons.enums.country.bm',
                bn: 'v2translations.commons.enums.country.bn',
                bo: 'v2translations.commons.enums.country.bo',
                bq: 'v2translations.commons.enums.country.bq',
                br: 'v2translations.commons.enums.country.br',
                bs: 'v2translations.commons.enums.country.bs',
                bt: 'v2translations.commons.enums.country.bt',
                bv: 'v2translations.commons.enums.country.bv',
                bw: 'v2translations.commons.enums.country.bw',
                by: 'v2translations.commons.enums.country.by',
                bz: 'v2translations.commons.enums.country.bz',
                ca: 'v2translations.commons.enums.country.ca',
                cc: 'v2translations.commons.enums.country.cc',
                cd: 'v2translations.commons.enums.country.cd',
                cf: 'v2translations.commons.enums.country.cf',
                cg: 'v2translations.commons.enums.country.cg',
                ch: 'v2translations.commons.enums.country.ch',
                ci: 'v2translations.commons.enums.country.ci',
                ck: 'v2translations.commons.enums.country.ck',
                cl: 'v2translations.commons.enums.country.cl',
                cm: 'v2translations.commons.enums.country.cm',
                cn: 'v2translations.commons.enums.country.cn',
                co: 'v2translations.commons.enums.country.co',
                cr: 'v2translations.commons.enums.country.cr',
                cu: 'v2translations.commons.enums.country.cu',
                cv: 'v2translations.commons.enums.country.cv',
                cw: 'v2translations.commons.enums.country.cw',
                cx: 'v2translations.commons.enums.country.cx',
                cy: 'v2translations.commons.enums.country.cy',
                cz: 'v2translations.commons.enums.country.cz',
                de: 'v2translations.commons.enums.country.de',
                dj: 'v2translations.commons.enums.country.dj',
                dk: 'v2translations.commons.enums.country.dk',
                dm: 'v2translations.commons.enums.country.dm',
                do: 'v2translations.commons.enums.country.do',
                dz: 'v2translations.commons.enums.country.dz',
                ec: 'v2translations.commons.enums.country.ec',
                ee: 'v2translations.commons.enums.country.ee',
                eg: 'v2translations.commons.enums.country.eg',
                eh: 'v2translations.commons.enums.country.eh',
                er: 'v2translations.commons.enums.country.er',
                es: 'v2translations.commons.enums.country.es',
                et: 'v2translations.commons.enums.country.et',
                fi: 'v2translations.commons.enums.country.fi',
                fj: 'v2translations.commons.enums.country.fj',
                fk: 'v2translations.commons.enums.country.fk',
                fm: 'v2translations.commons.enums.country.fm',
                fo: 'v2translations.commons.enums.country.fo',
                fr: 'v2translations.commons.enums.country.fr',
                ga: 'v2translations.commons.enums.country.ga',
                gb: 'v2translations.commons.enums.country.gb',
                gd: 'v2translations.commons.enums.country.gd',
                ge: 'v2translations.commons.enums.country.ge',
                gf: 'v2translations.commons.enums.country.gf',
                gg: 'v2translations.commons.enums.country.gg',
                gh: 'v2translations.commons.enums.country.gh',
                gi: 'v2translations.commons.enums.country.gi',
                gl: 'v2translations.commons.enums.country.gl',
                gm: 'v2translations.commons.enums.country.gm',
                gn: 'v2translations.commons.enums.country.gn',
                gp: 'v2translations.commons.enums.country.gp',
                gq: 'v2translations.commons.enums.country.gq',
                gr: 'v2translations.commons.enums.country.gr',
                gs: 'v2translations.commons.enums.country.gs',
                gt: 'v2translations.commons.enums.country.gt',
                gu: 'v2translations.commons.enums.country.gu',
                gw: 'v2translations.commons.enums.country.gw',
                gy: 'v2translations.commons.enums.country.gy',
                hk: 'v2translations.commons.enums.country.hk',
                hm: 'v2translations.commons.enums.country.hm',
                hn: 'v2translations.commons.enums.country.hn',
                hr: 'v2translations.commons.enums.country.hr',
                ht: 'v2translations.commons.enums.country.ht',
                hu: 'v2translations.commons.enums.country.hu',
                id: 'v2translations.commons.enums.country.id',
                ie: 'v2translations.commons.enums.country.ie',
                il: 'v2translations.commons.enums.country.il',
                im: 'v2translations.commons.enums.country.im',
                in: 'v2translations.commons.enums.country.in',
                io: 'v2translations.commons.enums.country.io',
                iq: 'v2translations.commons.enums.country.iq',
                ir: 'v2translations.commons.enums.country.ir',
                is: 'v2translations.commons.enums.country.is',
                it: 'v2translations.commons.enums.country.it',
                je: 'v2translations.commons.enums.country.je',
                jm: 'v2translations.commons.enums.country.jm',
                jo: 'v2translations.commons.enums.country.jo',
                jp: 'v2translations.commons.enums.country.jp',
                ke: 'v2translations.commons.enums.country.ke',
                kg: 'v2translations.commons.enums.country.kg',
                kh: 'v2translations.commons.enums.country.kh',
                ki: 'v2translations.commons.enums.country.ki',
                km: 'v2translations.commons.enums.country.km',
                kn: 'v2translations.commons.enums.country.kn',
                kp: 'v2translations.commons.enums.country.kp',
                kr: 'v2translations.commons.enums.country.kr',
                kw: 'v2translations.commons.enums.country.kw',
                ky: 'v2translations.commons.enums.country.ky',
                kz: 'v2translations.commons.enums.country.kz',
                la: 'v2translations.commons.enums.country.la',
                lb: 'v2translations.commons.enums.country.lb',
                lc: 'v2translations.commons.enums.country.lc',
                li: 'v2translations.commons.enums.country.li',
                lk: 'v2translations.commons.enums.country.lk',
                lr: 'v2translations.commons.enums.country.lr',
                ls: 'v2translations.commons.enums.country.ls',
                lt: 'v2translations.commons.enums.country.lt',
                lu: 'v2translations.commons.enums.country.lu',
                lv: 'v2translations.commons.enums.country.lv',
                ly: 'v2translations.commons.enums.country.ly',
                ma: 'v2translations.commons.enums.country.ma',
                mc: 'v2translations.commons.enums.country.mc',
                md: 'v2translations.commons.enums.country.md',
                me: 'v2translations.commons.enums.country.me',
                mf: 'v2translations.commons.enums.country.mf',
                mg: 'v2translations.commons.enums.country.mg',
                mh: 'v2translations.commons.enums.country.mh',
                mk: 'v2translations.commons.enums.country.mk',
                ml: 'v2translations.commons.enums.country.ml',
                mm: 'v2translations.commons.enums.country.mm',
                mn: 'v2translations.commons.enums.country.mn',
                mo: 'v2translations.commons.enums.country.mo',
                mp: 'v2translations.commons.enums.country.mp',
                mq: 'v2translations.commons.enums.country.mq',
                mr: 'v2translations.commons.enums.country.mr',
                ms: 'v2translations.commons.enums.country.ms',
                mt: 'v2translations.commons.enums.country.mt',
                mu: 'v2translations.commons.enums.country.mu',
                mv: 'v2translations.commons.enums.country.mv',
                mw: 'v2translations.commons.enums.country.mw',
                mx: 'v2translations.commons.enums.country.mx',
                my: 'v2translations.commons.enums.country.my',
                mz: 'v2translations.commons.enums.country.mz',
                na: 'v2translations.commons.enums.country.na',
                nc: 'v2translations.commons.enums.country.nc',
                ne: 'v2translations.commons.enums.country.ne',
                nf: 'v2translations.commons.enums.country.nf',
                ng: 'v2translations.commons.enums.country.ng',
                ni: 'v2translations.commons.enums.country.ni',
                nl: 'v2translations.commons.enums.country.nl',
                no: 'v2translations.commons.enums.country.no',
                np: 'v2translations.commons.enums.country.np',
                nr: 'v2translations.commons.enums.country.nr',
                nu: 'v2translations.commons.enums.country.nu',
                nz: 'v2translations.commons.enums.country.nz',
                om: 'v2translations.commons.enums.country.om',
                pa: 'v2translations.commons.enums.country.pa',
                pe: 'v2translations.commons.enums.country.pe',
                pf: 'v2translations.commons.enums.country.pf',
                pg: 'v2translations.commons.enums.country.pg',
                ph: 'v2translations.commons.enums.country.ph',
                pk: 'v2translations.commons.enums.country.pk',
                pl: 'v2translations.commons.enums.country.pl',
                pm: 'v2translations.commons.enums.country.pm',
                pn: 'v2translations.commons.enums.country.pn',
                pr: 'v2translations.commons.enums.country.pr',
                ps: 'v2translations.commons.enums.country.ps',
                pt: 'v2translations.commons.enums.country.pt',
                pw: 'v2translations.commons.enums.country.pw',
                py: 'v2translations.commons.enums.country.py',
                qa: 'v2translations.commons.enums.country.qa',
                re: 'v2translations.commons.enums.country.re',
                ro: 'v2translations.commons.enums.country.ro',
                rs: 'v2translations.commons.enums.country.rs',
                ru: 'v2translations.commons.enums.country.ru',
                rw: 'v2translations.commons.enums.country.rw',
                sa: 'v2translations.commons.enums.country.sa',
                sb: 'v2translations.commons.enums.country.sb',
                sc: 'v2translations.commons.enums.country.sc',
                sd: 'v2translations.commons.enums.country.sd',
                se: 'v2translations.commons.enums.country.se',
                sg: 'v2translations.commons.enums.country.sg',
                sh: 'v2translations.commons.enums.country.sh',
                si: 'v2translations.commons.enums.country.si',
                sj: 'v2translations.commons.enums.country.sj',
                sk: 'v2translations.commons.enums.country.sk',
                sl: 'v2translations.commons.enums.country.sl',
                sm: 'v2translations.commons.enums.country.sm',
                sn: 'v2translations.commons.enums.country.sn',
                so: 'v2translations.commons.enums.country.so',
                sr: 'v2translations.commons.enums.country.sr',
                ss: 'v2translations.commons.enums.country.ss',
                st: 'v2translations.commons.enums.country.st',
                sv: 'v2translations.commons.enums.country.sv',
                sx: 'v2translations.commons.enums.country.sx',
                sy: 'v2translations.commons.enums.country.sy',
                sz: 'v2translations.commons.enums.country.sz',
                tc: 'v2translations.commons.enums.country.tc',
                td: 'v2translations.commons.enums.country.td',
                tf: 'v2translations.commons.enums.country.tf',
                tg: 'v2translations.commons.enums.country.tg',
                th: 'v2translations.commons.enums.country.th',
                tj: 'v2translations.commons.enums.country.tj',
                tk: 'v2translations.commons.enums.country.tk',
                tl: 'v2translations.commons.enums.country.tl',
                tm: 'v2translations.commons.enums.country.tm',
                tn: 'v2translations.commons.enums.country.tn',
                to: 'v2translations.commons.enums.country.to',
                tr: 'v2translations.commons.enums.country.tr',
                tt: 'v2translations.commons.enums.country.tt',
                tv: 'v2translations.commons.enums.country.tv',
                tw: 'v2translations.commons.enums.country.tw',
                tz: 'v2translations.commons.enums.country.tz',
                ua: 'v2translations.commons.enums.country.ua',
                ug: 'v2translations.commons.enums.country.ug',
                um: 'v2translations.commons.enums.country.um',
                us: 'v2translations.commons.enums.country.us',
                uy: 'v2translations.commons.enums.country.uy',
                uz: 'v2translations.commons.enums.country.uz',
                va: 'v2translations.commons.enums.country.va',
                vc: 'v2translations.commons.enums.country.vc',
                ve: 'v2translations.commons.enums.country.ve',
                vg: 'v2translations.commons.enums.country.vg',
                vi: 'v2translations.commons.enums.country.vi',
                vn: 'v2translations.commons.enums.country.vn',
                vu: 'v2translations.commons.enums.country.vu',
                wf: 'v2translations.commons.enums.country.wf',
                ws: 'v2translations.commons.enums.country.ws',
                ye: 'v2translations.commons.enums.country.ye',
                yt: 'v2translations.commons.enums.country.yt',
                za: 'v2translations.commons.enums.country.za',
                zm: 'v2translations.commons.enums.country.zm',
                zw: 'v2translations.commons.enums.country.zw',
            },
            dateFormat: {
                DDMMYYYYDot: 'v2translations.commons.enums.dateFormat.DDMMYYYYDot',
                DDMMYYYYHyphen: 'v2translations.commons.enums.dateFormat.DDMMYYYYHyphen',
                DDMMYYYYSlash: 'v2translations.commons.enums.dateFormat.DDMMYYYYSlash',
                YYYYMMDDDot: 'v2translations.commons.enums.dateFormat.YYYYMMDDDot',
                YYYYMMDDHyphen: 'v2translations.commons.enums.dateFormat.YYYYMMDDHyphen',
                YYYYMMDDSlash: 'v2translations.commons.enums.dateFormat.YYYYMMDDSlash',
            },
            language: {
                english: 'v2translations.commons.enums.language.english',
                french: 'v2translations.commons.enums.language.french',
                german: 'v2translations.commons.enums.language.german',
            },
            modules: {
                contacts: 'v2translations.commons.enums.modules.contacts',
                core: 'v2translations.commons.enums.modules.core',
                crm: 'v2translations.commons.enums.modules.crm',
                dashboards: 'v2translations.commons.enums.modules.dashboards',
                financial: 'v2translations.commons.enums.modules.financial',
                gsearch: 'v2translations.commons.enums.modules.gsearch',
                schooldrive: 'v2translations.commons.enums.modules.schooldrive',
            },
            timeFormat: {
                fullDay: 'v2translations.commons.enums.timeFormat.fullDay',
                halfDay: 'v2translations.commons.enums.timeFormat.halfDay',
            },
            titles: {
                mr: 'v2translations.commons.enums.titles.mr',
                mrs: 'v2translations.commons.enums.titles.mrs',
            },
        },
        labels: {
            actions: 'v2translations.commons.labels.actions',
            all: 'v2translations.commons.labels.all',
            apply: 'v2translations.commons.labels.apply',
            attachment: 'v2translations.commons.labels.attachment',
            attachments: 'v2translations.commons.labels.attachments',
            date: 'v2translations.commons.labels.date',
            endDate: 'v2translations.commons.labels.endDate',
            error: 'v2translations.commons.labels.error',
            item: 'v2translations.commons.labels.item',
            items: 'v2translations.commons.labels.items',
            loading: 'v2translations.commons.labels.loading',
            loadMore: 'v2translations.commons.labels.loadMore',
            noDataFound: 'v2translations.commons.labels.noDataFound',
            noPermissionMessage: 'v2translations.commons.labels.noPermissionMessage',
            nothingHere: 'v2translations.commons.labels.nothingHere',
            or: 'v2translations.commons.labels.or',
            saving: 'v2translations.commons.labels.saving',
            search: 'v2translations.commons.labels.search',
            selectAll: 'v2translations.commons.labels.selectAll',
            selected: 'v2translations.commons.labels.selected',
            showLess: 'v2translations.commons.labels.showLess',
            showMore: 'v2translations.commons.labels.showMore',
            startDate: 'v2translations.commons.labels.startDate',
            unparseableServerError: 'v2translations.commons.labels.unparseableServerError',
            viewsSavedSuccessfully: 'v2translations.commons.labels.viewsSavedSuccessfully',
        },
        validations: {
            invalidField: 'v2translations.commons.validations.invalidField',
            invalidFieldDate: 'v2translations.commons.validations.invalidFieldDate',
            invalidFieldEmail: 'v2translations.commons.validations.invalidFieldEmail',
            invalidPhoneField: 'v2translations.commons.validations.invalidPhoneField',
            requiredField: 'v2translations.commons.validations.requiredField',
            requiredFieldDate: 'v2translations.commons.validations.requiredFieldDate',
            requiredFieldEmail: 'v2translations.commons.validations.requiredFieldEmail',
        },
    },
    core: {
        commons: {
            permissions: {
                dashboards: {
                    children: {
                        home: {
                            children: {
                                access: {
                                    description: 'v2translations.core.commons.permissions.dashboards.children.home.children.access.description',
                                    name: 'v2translations.core.commons.permissions.dashboards.children.home.children.access.name',
                                },
                                manageOwnViews: {
                                    description: 'v2translations.core.commons.permissions.dashboards.children.home.children.manageOwnViews.description',
                                    name: 'v2translations.core.commons.permissions.dashboards.children.home.children.manageOwnViews.name',
                                },
                                manageSharedViews: {
                                    description: 'v2translations.core.commons.permissions.dashboards.children.home.children.manageSharedViews.description',
                                    name: 'v2translations.core.commons.permissions.dashboards.children.home.children.manageSharedViews.name',
                                },
                            },
                            name: 'v2translations.core.commons.permissions.dashboards.children.home.name',
                        },
                        widgets: {
                            children: {
                                activeSessions: {
                                    children: {
                                        allUsers: {
                                            description: 'v2translations.core.commons.permissions.dashboards.children.widgets.children.activeSessions.children.allUsers.description',
                                            name: 'v2translations.core.commons.permissions.dashboards.children.widgets.children.activeSessions.children.allUsers.name',
                                        },
                                        currentUser: {
                                            description: 'v2translations.core.commons.permissions.dashboards.children.widgets.children.activeSessions.children.currentUser.description',
                                            name: 'v2translations.core.commons.permissions.dashboards.children.widgets.children.activeSessions.children.currentUser.name',
                                        },
                                    },
                                },
                            },
                        },
                    },
                    name: 'v2translations.core.commons.permissions.dashboards.name',
                },
                translations: {
                    children: {
                        listRead: {
                            description: 'v2translations.core.commons.permissions.translations.children.listRead.description',
                            name: 'v2translations.core.commons.permissions.translations.children.listRead.name',
                        },
                        write: {
                            description: 'v2translations.core.commons.permissions.translations.children.write.description',
                            name: 'v2translations.core.commons.permissions.translations.children.write.name',
                        },
                    },
                    name: 'v2translations.core.commons.permissions.translations.name',
                },
            },
            permissionsGroup: {
                activeSessions: {
                    name: 'v2translations.core.commons.permissionsGroup.activeSessions.name',
                },
                dashboards: {
                    name: 'v2translations.core.commons.permissionsGroup.dashboards.name',
                },
                home: {
                    name: 'v2translations.core.commons.permissionsGroup.home.name',
                },
                translations: {
                    name: 'v2translations.core.commons.permissionsGroup.translations.name',
                },
                widgets: {
                    name: 'v2translations.core.commons.permissionsGroup.widgets.name',
                },
            },
        },
        modules: {
            auth: {
                commons: {
                    actions: {
                        continue: {
                            label: 'v2translations.core.modules.auth.commons.actions.continue.label',
                        },
                        continueWith: {
                            label: 'v2translations.core.modules.auth.commons.actions.continueWith.label',
                        },
                        signIn: {
                            label: 'v2translations.core.modules.auth.commons.actions.signIn.label',
                        },
                        signInWith: {
                            label: 'v2translations.core.modules.auth.commons.actions.signInWith.label',
                        },
                    },
                    attributes: {
                        email: {
                            label: 'v2translations.core.modules.auth.commons.attributes.email.label',
                            placeholder: 'v2translations.core.modules.auth.commons.attributes.email.placeholder',
                        },
                        password: {
                            label: 'v2translations.core.modules.auth.commons.attributes.password.label',
                        },
                        phone: {
                            label: 'v2translations.core.modules.auth.commons.attributes.phone.label',
                        },
                    },
                    labels: {
                        alreadyUsing: 'v2translations.core.modules.auth.commons.labels.alreadyUsing',
                        createAccount: 'v2translations.core.modules.auth.commons.labels.createAccount',
                        email: 'v2translations.core.modules.auth.commons.labels.email',
                        newTo: 'v2translations.core.modules.auth.commons.labels.newTo',
                        phone: 'v2translations.core.modules.auth.commons.labels.phone',
                        rememberMe: 'v2translations.core.modules.auth.commons.labels.rememberMe',
                    },
                },
                pages: {
                    createAccount: {
                        labels: {
                            signInToExistingAccount: 'v2translations.core.modules.auth.pages.createAccount.labels.signInToExistingAccount',
                        },
                        title: 'v2translations.core.modules.auth.pages.createAccount.title',
                    },
                    forgotPassword: {
                        actions: {
                            resetPassword: {
                                label: 'v2translations.core.modules.auth.pages.forgotPassword.actions.resetPassword.label',
                            },
                        },
                        labels: {
                            didntReceiveLink: 'v2translations.core.modules.auth.pages.forgotPassword.labels.didntReceiveLink',
                            instruction: 'v2translations.core.modules.auth.pages.forgotPassword.labels.instruction',
                            linkSentTo: 'v2translations.core.modules.auth.pages.forgotPassword.labels.linkSentTo',
                            resend: 'v2translations.core.modules.auth.pages.forgotPassword.labels.resend',
                        },
                        title: 'v2translations.core.modules.auth.pages.forgotPassword.title',
                    },
                    resetPassword: {
                        actions: {
                            submit: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.actions.submit.label',
                                successMessage: 'v2translations.core.modules.auth.pages.resetPassword.actions.submit.successMessage',
                            },
                        },
                        attributes: {
                            confirmPassword: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.attributes.confirmPassword.label',
                            },
                            password: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.attributes.password.label',
                            },
                        },
                        title: 'v2translations.core.modules.auth.pages.resetPassword.title',
                        validations: {
                            passwordNotMatch: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordNotMatch',
                            passwordTooLong: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordTooLong',
                            passwordTooSmall: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordTooSmall',
                        },
                    },
                    signIn: {
                        labels: {
                            newTo: 'v2translations.core.modules.auth.pages.signIn.labels.newTo',
                            rememberMe: 'v2translations.core.modules.auth.pages.signIn.labels.rememberMe',
                            signManually: 'v2translations.core.modules.auth.pages.signIn.labels.signManually',
                            willSendEmailAdvise: 'v2translations.core.modules.auth.pages.signIn.labels.willSendEmailAdvise',
                        },
                        title: 'v2translations.core.modules.auth.pages.signIn.title',
                    },
                    signInManually: {
                        labels: {
                            forgotPassword: 'v2translations.core.modules.auth.pages.signInManually.labels.forgotPassword',
                            resetPassword: 'v2translations.core.modules.auth.pages.signInManually.labels.resetPassword',
                            signManually: 'v2translations.core.modules.auth.pages.signInManually.labels.signManually',
                            willSendEmailAdvise: 'v2translations.core.modules.auth.pages.signInManually.labels.willSendEmailAdvise',
                        },
                        title: 'v2translations.core.modules.auth.pages.signInManually.title',
                        validations: {
                            invalidCredentials: 'v2translations.core.modules.auth.pages.signInManually.validations.invalidCredentials',
                            invalidPassword: 'v2translations.core.modules.auth.pages.signInManually.validations.invalidPassword',
                            userNotFound: 'v2translations.core.modules.auth.pages.signInManually.validations.userNotFound',
                        },
                    },
                    unauthorized: {
                        labels: {
                            missingTranslation: 'v2translations.core.modules.auth.pages.unauthorized.labels.missingTranslation',
                        },
                        title: 'v2translations.core.modules.auth.pages.unauthorized.title',
                    },
                    verify: {
                        actions: {
                            resend: {
                                label: 'v2translations.core.modules.auth.pages.verify.actions.resend.label',
                            },
                            verify: {
                                label: 'v2translations.core.modules.auth.pages.verify.actions.verify.label',
                            },
                        },
                        attributes: {
                            verificationCode: {
                                label: 'v2translations.core.modules.auth.pages.verify.attributes.verificationCode.label',
                            },
                        },
                        labels: {
                            alreadyUsing: 'v2translations.core.modules.auth.pages.verify.labels.alreadyUsing',
                            magicCodeSent: 'v2translations.core.modules.auth.pages.verify.labels.magicCodeSent',
                            resendVerification: 'v2translations.core.modules.auth.pages.verify.labels.resendVerification',
                            resendVerificationTimer: 'v2translations.core.modules.auth.pages.verify.labels.resendVerificationTimer',
                            viaSms: 'v2translations.core.modules.auth.pages.verify.labels.viaSms',
                        },
                        title: 'v2translations.core.modules.auth.pages.verify.title',
                        validations: {
                            code: 'v2translations.core.modules.auth.pages.verify.validations.code',
                        },
                    },
                },
            },
            dateRangeSelectorWithDataPreview: {
                commons: {
                    labels: {
                        dateUnitDay: 'v2translations.core.modules.dateRangeSelectorWithDataPreview.commons.labels.dateUnitDay',
                        dateUnitMonth: 'v2translations.core.modules.dateRangeSelectorWithDataPreview.commons.labels.dateUnitMonth',
                    },
                },
            },
            header: {
                commons: {
                    labels: {
                        searchFieldPlaceholderDesktop: 'v2translations.core.modules.header.commons.labels.searchFieldPlaceholderDesktop',
                        searchFieldPlaceholderMobile: 'v2translations.core.modules.header.commons.labels.searchFieldPlaceholderMobile',
                    },
                },
            },
            listingPage: {
                commons: {
                    attributes: {
                        allUsers: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.allUsers.label',
                        },
                        name: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.name.label',
                        },
                        order: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.order.label',
                        },
                        roles: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.roles.label',
                        },
                        sharedWith: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.sharedWith.label',
                        },
                        users: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.users.label',
                        },
                        visibleColumns: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.visibleColumns.label',
                        },
                    },
                    labels: {
                        createNewViews: 'v2translations.core.modules.listingPage.commons.labels.createNewViews',
                        customizePage: 'v2translations.core.modules.listingPage.commons.labels.customizePage',
                        customizePageWithPageName: 'v2translations.core.modules.listingPage.commons.labels.customizePageWithPageName',
                        standardViewCantCustomize: 'v2translations.core.modules.listingPage.commons.labels.standardViewCantCustomize',
                        unsavedChangesDetected: 'v2translations.core.modules.listingPage.commons.labels.unsavedChangesDetected',
                    },
                },
            },
            notifications: {
                commons: {
                    actions: {
                        markAsRead: {
                            label: 'v2translations.core.modules.notifications.commons.actions.markAsRead.label',
                        },
                        viewAllNotifications: {
                            label: 'v2translations.core.modules.notifications.commons.actions.viewAllNotifications.label',
                        },
                    },
                    labels: {
                        emptyNotificationsMessage: 'v2translations.core.modules.notifications.commons.labels.emptyNotificationsMessage',
                    },
                },
                modules: {
                    schooldrive: {
                        commons: {
                            labels: {
                                accountingOperationsPayorderPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsPayorderPlural',
                                accountingOperationsPayorderSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsPayorderSingular',
                                accountingOperationsPayreqPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsPayreqPlural',
                                accountingOperationsPayreqSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsPayreqSingular',
                                accountingOperationsSalesUnidentifiedPaymentsPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsSalesUnidentifiedPaymentsPlural',
                                accountingOperationsSalesUnidentifiedPaymentsSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsSalesUnidentifiedPaymentsSingular',
                                accountingOperationsSalesUninvoicedItemsPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsSalesUninvoicedItemsPlural',
                                accountingOperationsSalesUninvoicedItemsSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.accountingOperationsSalesUninvoicedItemsSingular',
                                customersSimilarCustomersPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.customersSimilarCustomersPlural',
                                customersSimilarCustomersSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.customersSimilarCustomersSingular',
                                emailSmsAutoresponderHistoryErrorsPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.emailSmsAutoresponderHistoryErrorsPlural',
                                emailSmsAutoresponderHistoryErrorsSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.emailSmsAutoresponderHistoryErrorsSingular',
                                emailSmsAutoresponderModerationMessagesPlural: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.emailSmsAutoresponderModerationMessagesPlural',
                                emailSmsAutoresponderModerationMessagesSingular: 'v2translations.core.modules.notifications.modules.schooldrive.commons.labels.emailSmsAutoresponderModerationMessagesSingular',
                            },
                        },
                    },
                },
                title: 'v2translations.core.modules.notifications.title',
            },
            popup: {
                commons: {
                    labels: {
                        pendingChanges: 'v2translations.core.modules.popup.commons.labels.pendingChanges',
                        unsavedChangesDescription: 'v2translations.core.modules.popup.commons.labels.unsavedChangesDescription',
                        unsavedChangesMessage: 'v2translations.core.modules.popup.commons.labels.unsavedChangesMessage',
                    },
                },
            },
            table: {
                commons: {
                    labels: {
                        itemsPerPageLabel: 'v2translations.core.modules.table.commons.labels.itemsPerPageLabel',
                        resultCount: 'v2translations.core.modules.table.commons.labels.resultCount',
                        search: 'v2translations.core.modules.table.commons.labels.search',
                    },
                },
            },
            tenant: {
                pages: {
                    settings: {
                        labels: {
                            dateFormat: 'v2translations.core.modules.tenant.pages.settings.labels.dateFormat',
                            language: 'v2translations.core.modules.tenant.pages.settings.labels.language',
                            languageAndRegion: 'v2translations.core.modules.tenant.pages.settings.labels.languageAndRegion',
                            timeFormat: 'v2translations.core.modules.tenant.pages.settings.labels.timeFormat',
                        },
                    },
                },
            },
            timeline: {
                commons: {
                    actions: {
                        expandAll: {
                            label: 'v2translations.core.modules.timeline.commons.actions.expandAll.label',
                        },
                        reverseOrder: {
                            label: 'v2translations.core.modules.timeline.commons.actions.reverseOrder.label',
                        },
                    },
                },
            },
            translations: {
                commons: {
                    labels: {
                        Key: 'v2translations.core.modules.translations.commons.labels.Key',
                        translationParametersMustNotBeChanged: 'v2translations.core.modules.translations.commons.labels.translationParametersMustNotBeChanged',
                    },
                },
                title: 'v2translations.core.modules.translations.title',
            },
            user: {
                pages: {
                    profile: {
                        attributes: {
                            address: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.address.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.address.placeholder',
                            },
                            birthday: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.birthday.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.birthday.placeholder',
                            },
                            city: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.city.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.city.placeholder',
                            },
                            country: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.country.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.country.placeholder',
                            },
                            displayName: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.displayName.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.displayName.placeholder',
                            },
                            email: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.email.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.email.placeholder',
                            },
                            fullName: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.fullName.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.fullName.placeholder',
                            },
                            phone: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.phone.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.phone.placeholder',
                            },
                            state: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.state.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.state.placeholder',
                            },
                            title: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.title.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.title.placeholder',
                            },
                            zipCode: {
                                label: 'v2translations.core.modules.user.pages.profile.attributes.zipCode.label',
                                placeholder: 'v2translations.core.modules.user.pages.profile.attributes.zipCode.placeholder',
                            },
                        },
                        labels: {
                            address: 'v2translations.core.modules.user.pages.profile.labels.address',
                            basicInformation: 'v2translations.core.modules.user.pages.profile.labels.basicInformation',
                            cropImage: 'v2translations.core.modules.user.pages.profile.labels.cropImage',
                            profile: 'v2translations.core.modules.user.pages.profile.labels.profile',
                            successUpdateDescription: 'v2translations.core.modules.user.pages.profile.labels.successUpdateDescription',
                            successUpdateTitle: 'v2translations.core.modules.user.pages.profile.labels.successUpdateTitle',
                        },
                    },
                },
            },
            userSettings: {
                commons: {
                    actions: {
                        new: {
                            label: 'v2translations.core.modules.userSettings.commons.actions.new.label',
                        },
                        signOut: {
                            label: 'v2translations.core.modules.userSettings.commons.actions.signOut.label',
                        },
                    },
                    labels: {
                        billing: 'v2translations.core.modules.userSettings.commons.labels.billing',
                        preferences: 'v2translations.core.modules.userSettings.commons.labels.preferences',
                        profile: 'v2translations.core.modules.userSettings.commons.labels.profile',
                        signInSecurity: 'v2translations.core.modules.userSettings.commons.labels.signInSecurity',
                        switchWorkspace: 'v2translations.core.modules.userSettings.commons.labels.switchWorkspace',
                        users: 'v2translations.core.modules.userSettings.commons.labels.users',
                    },
                },
            },
        },
        pages: {
            home: {
                labels: {
                    quicklyDashboardAccessDashboardDescription: 'v2translations.core.pages.home.labels.quicklyDashboardAccessDashboardDescription',
                    quicklyDashboardAccessDashboardName: 'v2translations.core.pages.home.labels.quicklyDashboardAccessDashboardName',
                    quicklyDashboardAccessDashboardView: 'v2translations.core.pages.home.labels.quicklyDashboardAccessDashboardView',
                    tasks: 'v2translations.core.pages.home.labels.tasks',
                },
            },
        },
    },
    crm: {
        pages: {
            leads: {
                labels: {
                    email: 'v2translations.crm.pages.leads.labels.email',
                    firstName: 'v2translations.crm.pages.leads.labels.firstName',
                    id: 'v2translations.crm.pages.leads.labels.id',
                    lastName: 'v2translations.crm.pages.leads.labels.lastName',
                    product: 'v2translations.crm.pages.leads.labels.product',
                    status: 'v2translations.crm.pages.leads.labels.status',
                    title: 'v2translations.crm.pages.leads.labels.title',
                },
                title: 'v2translations.crm.pages.leads.title',
            },
        },
        title: 'v2translations.crm.title',
    },
    dashboards: {
        commons: {
            actions: {
                customizeDashboard: {
                    label: 'v2translations.dashboards.commons.actions.customizeDashboard.label',
                },
            },
            enums: {
                widget_collections: {
                    dashboard_generic: 'v2translations.dashboards.commons.enums.widget_collections.dashboard_generic',
                },
                widgetDropdownDate: {
                    last12Months: 'v2translations.dashboards.commons.enums.widgetDropdownDate.last12Months',
                    last30Days: 'v2translations.dashboards.commons.enums.widgetDropdownDate.last30Days',
                    last3Months: 'v2translations.dashboards.commons.enums.widgetDropdownDate.last3Months',
                    last6Months: 'v2translations.dashboards.commons.enums.widgetDropdownDate.last6Months',
                    last7Days: 'v2translations.dashboards.commons.enums.widgetDropdownDate.last7Days',
                    lastMonth: 'v2translations.dashboards.commons.enums.widgetDropdownDate.lastMonth',
                    lastWeek: 'v2translations.dashboards.commons.enums.widgetDropdownDate.lastWeek',
                    lastYear: 'v2translations.dashboards.commons.enums.widgetDropdownDate.lastYear',
                    thisMonth: 'v2translations.dashboards.commons.enums.widgetDropdownDate.thisMonth',
                    thisWeek: 'v2translations.dashboards.commons.enums.widgetDropdownDate.thisWeek',
                    thisYear: 'v2translations.dashboards.commons.enums.widgetDropdownDate.thisYear',
                },
                widgetInstructions: {
                    static_text: 'v2translations.dashboards.commons.enums.widgetInstructions.static_text',
                },
                widgets: {
                    static_text: 'v2translations.dashboards.commons.enums.widgets.static_text',
                },
            },
            labels: {
                changeTabLostUnsavedData: 'v2translations.dashboards.commons.labels.changeTabLostUnsavedData',
                clickToEnableActions: 'v2translations.dashboards.commons.labels.clickToEnableActions',
                closePopupLostUnsavedData: 'v2translations.dashboards.commons.labels.closePopupLostUnsavedData',
                deleteView: 'v2translations.dashboards.commons.labels.deleteView',
                deleteViewAdviseDescription: 'v2translations.dashboards.commons.labels.deleteViewAdviseDescription',
                deleteViewAdviseMessage: 'v2translations.dashboards.commons.labels.deleteViewAdviseMessage',
                deleteViewAdviseTitle: 'v2translations.dashboards.commons.labels.deleteViewAdviseTitle',
                pendingChanges: 'v2translations.dashboards.commons.labels.pendingChanges',
                staticTextPlaceholder: 'v2translations.dashboards.commons.labels.staticTextPlaceholder',
                unsavedChanges: 'v2translations.dashboards.commons.labels.unsavedChanges',
            },
        },
        pages: {
            dashboards: {
                title: 'v2translations.dashboards.pages.dashboards.title',
            },
        },
        title: 'v2translations.dashboards.title',
    },
    gSearch: {
        commons: {
            actions: {
                showMore: {
                    label: 'v2translations.gSearch.commons.actions.showMore.label',
                },
            },
            labels: {
                results: 'v2translations.gSearch.commons.labels.results',
                totalItemsDisplay: 'v2translations.gSearch.commons.labels.totalItemsDisplay',
            },
        },
    },
    menus: {
        core: {
            children: {
                dashboards: {
                    children: {
                        tasks: {
                            title: 'v2translations.menus.core.children.dashboards.children.tasks.title',
                        },
                    },
                    title: 'v2translations.menus.core.children.dashboards.title',
                },
                favorites: {
                    title: 'v2translations.menus.core.children.favorites.title',
                },
                home: {
                    title: 'v2translations.menus.core.children.home.title',
                },
            },
        },
        crm: {
            children: {
                dashboard: {
                    title: 'v2translations.menus.crm.children.dashboard.title',
                },
                leads: {
                    children: {
                        leads: {
                            title: 'v2translations.menus.crm.children.leads.children.leads.title',
                        },
                    },
                    title: 'v2translations.menus.crm.children.leads.title',
                },
            },
            title: 'v2translations.menus.crm.title',
        },
        financial: {
            children: {
                accounting: {
                    children: {
                        accountsPayable: {
                            title: 'v2translations.menus.financial.children.accounting.children.accountsPayable.title',
                        },
                        accountsReceivable: {
                            title: 'v2translations.menus.financial.children.accounting.children.accountsReceivable.title',
                        },
                        cashFlow: {
                            title: 'v2translations.menus.financial.children.accounting.children.cashFlow.title',
                        },
                        management: {
                            children: {
                                bankAccounts: {
                                    title: 'v2translations.menus.financial.children.accounting.children.management.children.bankAccounts.title',
                                },
                            },
                            title: 'v2translations.menus.financial.children.accounting.children.management.title',
                        },
                        transactions: {
                            title: 'v2translations.menus.financial.children.accounting.children.transactions.title',
                        },
                    },
                    title: 'v2translations.menus.financial.children.accounting.title',
                },
                dashboard: {
                    title: 'v2translations.menus.financial.children.dashboard.title',
                },
                purchases: {
                    children: {
                        management: {
                            children: {
                                products: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.products.title',
                                },
                                services: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.services.title',
                                },
                                suppliers: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.suppliers.title',
                                },
                            },
                            title: 'v2translations.menus.financial.children.purchases.children.management.title',
                        },
                        purchase: {
                            title: 'v2translations.menus.financial.children.purchases.children.purchase.title',
                        },
                    },
                    title: 'v2translations.menus.financial.children.purchases.title',
                },
                sales: {
                    children: {
                        management: {
                            children: {
                                customers: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.customers.title',
                                },
                                products: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.products.title',
                                },
                                services: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.services.title',
                                },
                                shipping: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.shipping.title',
                                },
                            },
                            title: 'v2translations.menus.financial.children.sales.children.management.title',
                        },
                        quotes: {
                            title: 'v2translations.menus.financial.children.sales.children.quotes.title',
                        },
                        sales: {
                            title: 'v2translations.menus.financial.children.sales.children.sales.title',
                        },
                        serviceOrders: {
                            title: 'v2translations.menus.financial.children.sales.children.serviceOrders.title',
                        },
                    },
                    title: 'v2translations.menus.financial.children.sales.title',
                },
                stock: {
                    children: {
                        inventory: {
                            title: 'v2translations.menus.financial.children.stock.children.inventory.title',
                        },
                        management: {
                            children: {
                                measurementUnits: {
                                    title: 'v2translations.menus.financial.children.stock.children.management.children.measurementUnits.title',
                                },
                                products: {
                                    title: 'v2translations.menus.financial.children.stock.children.management.children.products.title',
                                },
                            },
                            title: 'v2translations.menus.financial.children.stock.children.management.title',
                        },
                    },
                    title: 'v2translations.menus.financial.children.stock.title',
                },
            },
            title: 'v2translations.menus.financial.title',
        },
        schooldrive: {
            children: {
                accounting: {
                    children: {
                        definitions: {
                            children: {
                                addonsDiscounts: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.addonsDiscounts.title',
                                },
                                bankAccounts: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.bankAccounts.title',
                                },
                                chartOfAccounts: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.chartOfAccounts.title',
                                },
                                creditCards: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.creditCards.title',
                                },
                                parameters: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.parameters.title',
                                },
                                suppliers: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.children.suppliers.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.accounting.children.definitions.title',
                        },
                        operations: {
                            children: {
                                bookkeeping: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.bookkeeping.title',
                                },
                                creditCardTransactions: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.creditCardTransactions.title',
                                },
                                paymentOrders: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.paymentOrders.title',
                                },
                                paymentRequisitions: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.paymentRequisitions.title',
                                },
                                salaryCalculator: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.salaryCalculator.title',
                                },
                                sales: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.operations.children.sales.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.accounting.children.operations.title',
                        },
                        reports: {
                            children: {
                                bankTransactions: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.reports.children.bankTransactions.title',
                                },
                                paypalConciliation: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.reports.children.paypalConciliation.title',
                                },
                                payrollTaxes: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.reports.children.payrollTaxes.title',
                                },
                                salaryReport: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.reports.children.salaryReport.title',
                                },
                                twintConciliation: {
                                    title: 'v2translations.menus.schooldrive.children.accounting.children.reports.children.twintConciliation.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.accounting.children.reports.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.accounting.title',
                },
                admin: {
                    children: {
                        administrativeDocFolders: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.administrativeDocFolders.title',
                        },
                        automaticDocuments: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.automaticDocuments.title',
                        },
                        autoresponders: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.autoresponders.title',
                        },
                        computers: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.computers.title',
                        },
                        courseCategory: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.courseCategory.title',
                        },
                        coursePlaces: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.coursePlaces.title',
                        },
                        customerTags: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.customerTags.title',
                        },
                        documentation: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.documentation.title',
                        },
                        dynamicForms: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.dynamicForms.title',
                        },
                        dynamicForms2: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.dynamicForms2.title',
                        },
                        examExceptions: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.examExceptions.title',
                        },
                        listMainCourse: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.listMainCourse.title',
                        },
                        professions: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.professions.title',
                        },
                        quality: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.quality.title',
                        },
                        recruitment: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.recruitment.title',
                        },
                        taskQueues: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.taskQueues.title',
                        },
                        taskTypes: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.taskTypes.title',
                        },
                        trainingAndModules: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.trainingAndModules.title',
                        },
                        translations: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.translations.title',
                        },
                        users: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.users.title',
                        },
                        userSettings: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.userSettings.title',
                        },
                        vacations: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.vacations.title',
                        },
                        workflows: {
                            title: 'v2translations.menus.schooldrive.children.admin.children.workflows.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.admin.title',
                },
                calendar: {
                    children: {
                        calendar: {
                            title: 'v2translations.menus.schooldrive.children.calendar.children.calendar.title',
                        },
                        employeeVacations: {
                            title: 'v2translations.menus.schooldrive.children.calendar.children.employeeVacations.title',
                        },
                        events: {
                            title: 'v2translations.menus.schooldrive.children.calendar.children.events.title',
                        },
                        myCalendar: {
                            title: 'v2translations.menus.schooldrive.children.calendar.children.myCalendar.title',
                        },
                        officialHolidays: {
                            title: 'v2translations.menus.schooldrive.children.calendar.children.officialHolidays.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.calendar.title',
                },
                course: {
                    children: {
                        allCourses: {
                            title: 'v2translations.menus.schooldrive.children.course.children.allCourses.title',
                        },
                        browseCourses: {
                            title: 'v2translations.menus.schooldrive.children.course.children.browseCourses.title',
                        },
                        officialStudentReport: {
                            title: 'v2translations.menus.schooldrive.children.course.children.officialStudentReport.title',
                        },
                        yourCourses: {
                            title: 'v2translations.menus.schooldrive.children.course.children.yourCourses.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.course.title',
                },
                customers: {
                    children: {
                        acquiredModules: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.acquiredModules.title',
                        },
                        createCustomer: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.createCustomer.title',
                        },
                        importCustomer: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.importCustomer.title',
                        },
                        mergeCustomer: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.mergeCustomer.title',
                        },
                        recentSubscriptions: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.recentSubscriptions.title',
                        },
                        reports: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.reports.title',
                        },
                        searchCustomer: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.searchCustomer.title',
                        },
                        similarCustomers: {
                            title: 'v2translations.menus.schooldrive.children.customers.children.similarCustomers.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.customers.title',
                },
                dashboard: {
                    title: 'v2translations.menus.schooldrive.children.dashboard.title',
                },
                digitalMarketing: {
                    children: {
                        googleADS: {
                            title: 'v2translations.menus.schooldrive.children.digitalMarketing.children.googleADS.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.digitalMarketing.title',
                },
                docs: {
                    children: {
                        repository: {
                            title: 'v2translations.menus.schooldrive.children.docs.children.repository.title',
                        },
                        sendDocuments: {
                            title: 'v2translations.menus.schooldrive.children.docs.children.sendDocuments.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.docs.title',
                },
                documentation: {
                    title: 'v2translations.menus.schooldrive.children.documentation.title',
                },
                emailSms: {
                    children: {
                        emails: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.emails.title',
                        },
                        history: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.history.title',
                        },
                        moderation: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.moderation.title',
                        },
                        predefinedEmails: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.predefinedEmails.title',
                        },
                        queue: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.queue.title',
                        },
                        sms: {
                            title: 'v2translations.menus.schooldrive.children.emailSms.children.sms.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.emailSms.title',
                },
                home: {
                    children: {
                        delegatedTasks: {
                            title: 'v2translations.menus.schooldrive.children.home.children.delegatedTasks.title',
                        },
                        Watchtower: {
                            title: 'v2translations.menus.schooldrive.children.home.children.Watchtower.title',
                        },
                        whatsUp: {
                            title: 'v2translations.menus.schooldrive.children.home.children.whatsUp.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.home.title',
                },
                tasks: {
                    children: {
                        allTasks: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.allTasks.title',
                        },
                        dashboards: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.dashboards.title',
                        },
                        myTasks: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.myTasks.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.tasks.title',
                },
                website: {
                    children: {
                        otherWebsites: {
                            children: {
                                df: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.otherWebsites.children.df.title',
                                },
                                hr: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.otherWebsites.children.hr.title',
                                },
                                pay: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.otherWebsites.children.pay.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.website.children.otherWebsites.title',
                        },
                        publicWebsites: {
                            children: {
                                essr: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.publicWebsites.children.essr.title',
                                },
                                gsds: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.publicWebsites.children.gsds.title',
                                },
                                gstf: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.publicWebsites.children.gstf.title',
                                },
                                pmc: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.publicWebsites.children.pmc.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.website.children.publicWebsites.title',
                        },
                        studentWebsites: {
                            children: {
                                etu: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.studentWebsites.children.etu.title',
                                },
                                stud: {
                                    title: 'v2translations.menus.schooldrive.children.website.children.studentWebsites.children.stud.title',
                                },
                            },
                            title: 'v2translations.menus.schooldrive.children.website.children.studentWebsites.title',
                        },
                    },
                    title: 'v2translations.menus.schooldrive.children.website.title',
                },
            },
            title: 'v2translations.menus.schooldrive.title',
        },
    },
    schooldrive: {
        commons: {
            permissions: {
                calendar: {
                    children: {
                        access: {
                            description: 'v2translations.schooldrive.commons.permissions.calendar.children.access.description',
                            name: 'v2translations.schooldrive.commons.permissions.calendar.children.access.name',
                        },
                        frontOffice: {
                            children: {
                                coursesOfAnyUser: {
                                    description: 'v2translations.schooldrive.commons.permissions.calendar.children.frontOffice.children.coursesOfAnyUser.description',
                                    name: 'v2translations.schooldrive.commons.permissions.calendar.children.frontOffice.children.coursesOfAnyUser.name',
                                },
                            },
                        },
                    },
                },
                dashboards: {
                    children: {
                        home: {
                            children: {
                                access: {
                                    description: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.access.description',
                                    name: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.access.name',
                                },
                                manageOwnViews: {
                                    description: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.manageOwnViews.description',
                                    name: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.manageOwnViews.name',
                                },
                                manageSharedViews: {
                                    description: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.manageSharedViews.description',
                                    name: 'v2translations.schooldrive.commons.permissions.dashboards.children.home.children.manageSharedViews.name',
                                },
                            },
                        },
                    },
                },
                digitalMarketing: {
                    children: {
                        access: {
                            description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.access.description',
                            name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.access.name',
                        },
                        dashboard: {
                            children: {
                                access: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.dashboard.children.access.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.dashboard.children.access.name',
                                },
                            },
                        },
                        googleADS: {
                            children: {
                                access: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.access.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.access.name',
                                },
                                essr: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.essr.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.essr.name',
                                },
                                gsds: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.gsds.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.gsds.name',
                                },
                                gstf: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.gstf.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.gstf.name',
                                },
                                permismoinscher: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.permismoinscher.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.permismoinscher.name',
                                },
                                read: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.read.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.read.name',
                                },
                                write: {
                                    description: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.write.description',
                                    name: 'v2translations.schooldrive.commons.permissions.digitalMarketing.children.googleADS.children.write.name',
                                },
                            },
                        },
                    },
                },
            },
            permissionsGroup: {
                calendar: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.calendar.name',
                },
                dashboard: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.dashboard.name',
                },
                dashboards: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.dashboards.name',
                },
                digitalMarketing: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.digitalMarketing.name',
                },
                frontOffice: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.frontOffice.name',
                },
                googleADS: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.googleADS.name',
                },
                home: {
                    name: 'v2translations.schooldrive.commons.permissionsGroup.home.name',
                },
            },
        },
        modules: {
            calendar: {
                commons: {
                    permissions: {
                        calendar: {
                            children: {
                                frontOffice: {
                                    children: {
                                        coursesOfAnyUser: {
                                            description: 'v2translations.schooldrive.modules.calendar.commons.permissions.calendar.children.frontOffice.children.coursesOfAnyUser.description',
                                            name: 'v2translations.schooldrive.modules.calendar.commons.permissions.calendar.children.frontOffice.children.coursesOfAnyUser.name',
                                        },
                                    },
                                    name: 'v2translations.schooldrive.modules.calendar.commons.permissions.calendar.children.frontOffice.name',
                                },
                            },
                            description: 'v2translations.schooldrive.modules.calendar.commons.permissions.calendar.description',
                            name: 'v2translations.schooldrive.modules.calendar.commons.permissions.calendar.name',
                        },
                    },
                },
                pages: {
                    calendar: {
                        labels: {
                            archiveEvent: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.archiveEvent',
                            archiveVacation: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.archiveVacation',
                            assistant: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.assistant',
                            buffer: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.buffer',
                            cancelled: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.cancelled',
                            categories: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.categories',
                            classrooms: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.classrooms',
                            country: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.country',
                            course: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course',
                            course_status_buffer: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_buffer',
                            course_status_cancelled: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_cancelled',
                            course_status_draft: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_draft',
                            course_status_over: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_over',
                            course_status_pending_cancellation: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_pending_cancellation',
                            course_status_running: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_running',
                            course_status_scheduled: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_scheduled',
                            course_status_undefined: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.course_status_undefined',
                            data: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.data',
                            draft: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.draft',
                            editEvent: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.editEvent',
                            editVacation: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.editVacation',
                            frontOffice: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.frontOffice',
                            frontOfficeTitle: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.frontOfficeTitle',
                            general: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.general',
                            instructor: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.instructor',
                            insufficient_participation_abort: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.insufficient_participation_abort',
                            modalities: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.modalities',
                            modality: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.modality',
                            moderator: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.moderator',
                            noUpcomingEvents: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.noUpcomingEvents',
                            observer: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.observer',
                            over: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.over',
                            pending_cancellation: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.pending_cancellation',
                            running: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.running',
                            scheduled: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.scheduled',
                            seats: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.seats',
                            sideCalendar: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.sideCalendar',
                            state: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.state',
                            status: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.status',
                            students: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.students',
                            Subscription_modality_name_mixed: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.Subscription_modality_name_mixed',
                            Subscription_modality_name_presential: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.Subscription_modality_name_presential',
                            Subscription_modality_name_undefined: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.Subscription_modality_name_undefined',
                            Subscription_modality_name_video_conference: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.Subscription_modality_name_video_conference',
                            subscriptions: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.subscriptions',
                            undefined: 'v2translations.schooldrive.modules.calendar.pages.calendar.labels.undefined',
                        },
                        title: 'v2translations.schooldrive.modules.calendar.pages.calendar.title',
                    },
                },
                title: 'v2translations.schooldrive.modules.calendar.title',
            },
            course: {
                pages: {
                    createCourse: {
                        labels: {
                            blockScheduler: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.blockScheduler',
                            BLSCourseHourInfoCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.BLSCourseHourInfoCourse',
                            blsScheduleAM: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.blsScheduleAM',
                            blsSchedulePM: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.blsSchedulePM',
                            blsScheduleSoir: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.blsScheduleSoir',
                            calendar: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.calendar',
                            calendarBlockScheduler: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.calendarBlockScheduler',
                            courseCategory: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseCategory',
                            courseCity: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseCity',
                            courseDate: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseDate',
                            coursePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.coursePlace',
                            courseScheduleType: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseScheduleType',
                            dateBlockSelect: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.dateBlockSelect',
                            doYouWantToCreate: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.doYouWantToCreate',
                            geneve: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.geneve',
                            instructions: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.instructions',
                            lausanne: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.lausanne',
                            officialHolidays: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.officialHolidays',
                            options: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.options',
                            otherCoursesInTheSamePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.otherCoursesInTheSamePlace',
                            reviewCourseInformation: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.reviewCourseInformation',
                            samaCourseHourInfoWeekCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.samaCourseHourInfoWeekCourse',
                            samaCourseHourInfoWeekendCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.samaCourseHourInfoWeekendCourse',
                            selectCourseCategory: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCourseCategory',
                            selectCoursePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCoursePlace',
                            selectCourseScheduledType: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCourseScheduledType',
                            sensiGECourseHourInfoCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.sensiGECourseHourInfoCourse',
                            sensiVDCourseHourInfoCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.sensiVDCourseHourInfoCourse',
                            title: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.title',
                            weekCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.weekCourse',
                            weekendCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.weekendCourse',
                        },
                        title: 'v2translations.schooldrive.modules.course.pages.createCourse.title',
                    },
                },
                title: 'v2translations.schooldrive.modules.course.title',
            },
            digitalMarketing: {
                commons: {
                    permissions: {
                        access: {
                            description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissions.access.description',
                            name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissions.access.name',
                        },
                    },
                    permissionsGroup: {
                        children: {
                            dashboard: {
                                children: {
                                    access: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.dashboard.children.access.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.dashboard.children.access.name',
                                    },
                                },
                                name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.dashboard.name',
                            },
                            googleADS: {
                                children: {
                                    access: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.access.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.access.name',
                                    },
                                    essr: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.essr.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.essr.name',
                                    },
                                    gsds: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.gsds.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.gsds.name',
                                    },
                                    gstf: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.gstf.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.gstf.name',
                                    },
                                    permismoinscher: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.permismoinscher.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.permismoinscher.name',
                                    },
                                    read: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.read.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.read.name',
                                    },
                                    write: {
                                        description: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.write.description',
                                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.children.write.name',
                                    },
                                },
                                name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.children.googleADS.name',
                            },
                        },
                        name: 'v2translations.schooldrive.modules.digitalMarketing.commons.permissionsGroup.name',
                    },
                },
                pages: {
                    dashboard: {
                        title: 'v2translations.schooldrive.modules.digitalMarketing.pages.dashboard.title',
                    },
                    googleADS: {
                        attributes: {
                            conversionActionId: {
                                label: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.attributes.conversionActionId.label',
                            },
                            conversionActionLabel: {
                                label: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.attributes.conversionActionLabel.label',
                            },
                            conversionActionName: {
                                label: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.attributes.conversionActionName.label',
                            },
                        },
                        labels: {
                            categoryTitle: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.categoryTitle',
                            conversionActionIdInputErrorMessage: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.conversionActionIdInputErrorMessage',
                            leadConversionId: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.leadConversionId',
                            leadConversionLabel: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.leadConversionLabel',
                            leadConversionName: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.leadConversionName',
                            leadTitle: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.leadTitle',
                            saleConversionId: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.saleConversionId',
                            saleConversionLabel: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.saleConversionLabel',
                            saleConversionName: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.saleConversionName',
                            saleTitle: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.saleTitle',
                            saveMessage: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.saveMessage',
                            website: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.labels.website',
                        },
                        title: 'v2translations.schooldrive.modules.digitalMarketing.pages.googleADS.title',
                    },
                },
                title: 'v2translations.schooldrive.modules.digitalMarketing.title',
            },
            tasks: {
                commons: {
                    labels: {
                        myTasksNoTasksFound: 'v2translations.schooldrive.modules.tasks.commons.labels.myTasksNoTasksFound',
                        noDataFound: 'v2translations.schooldrive.modules.tasks.commons.labels.noDataFound',
                    },
                },
                pages: {
                    allTasks: {
                        title: 'v2translations.schooldrive.modules.tasks.pages.allTasks.title',
                    },
                    dashboard: {
                        attributes: {
                            allUsers: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.allUsers.label',
                            },
                            name: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.name.label',
                            },
                            roles: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.roles.label',
                            },
                            users: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.users.label',
                            },
                        },
                        enums: {
                            widget_collections: {
                                schooldrive_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widget_collections.schooldrive_tasks',
                            },
                            widgetInstructions: {
                                cancelled_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.cancelled_tasks',
                                cancelled_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.cancelled_tasks_by_queue',
                                completed_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.completed_tasks',
                                completed_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.completed_tasks_by_queue',
                                completed_tasks_history: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.completed_tasks_history',
                                doable_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.doable_tasks_count',
                                due_today_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.due_today_tasks_count',
                                list_overdue_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.list_overdue_tasks',
                                open_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.open_tasks_by_queue',
                                open_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.open_tasks_count',
                                overdue_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.overdue_tasks_by_queue',
                                overdue_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.overdue_tasks_count',
                                quickly_task_dashboard_access: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.quickly_task_dashboard_access',
                                task_progress: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.task_progress',
                                upcoming_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgetInstructions.upcoming_tasks_count',
                            },
                            widgets: {
                                cancelled_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.cancelled_tasks',
                                cancelled_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.cancelled_tasks_by_queue',
                                completed_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks',
                                completed_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks_by_queue',
                                completed_tasks_history: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks_history',
                                doable: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.doable',
                                doable_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.doable_tasks_count',
                                due_today: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.due_today',
                                due_today_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.due_today_tasks_count',
                                list_overdue_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.list_overdue_tasks',
                                open_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.open_tasks_by_queue',
                                open_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.open_tasks_count',
                                overdue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.overdue',
                                overdue_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.overdue_tasks_by_queue',
                                overdue_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.overdue_tasks_count',
                                quickly_task_dashboard_access: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.quickly_task_dashboard_access',
                                task_progress: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.task_progress',
                                upcoming: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.upcoming',
                                upcoming_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.upcoming_tasks_count',
                            },
                        },
                        labels: {
                            addTextPlaceholder: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.addTextPlaceholder',
                            addWidgets: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.addWidgets',
                            cancelled: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.cancelled',
                            cancelledTasksByQueueNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.cancelledTasksByQueueNoDataFoundMessage',
                            completed: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.completed',
                            completedTasksByQueueNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.completedTasksByQueueNoDataFoundMessage',
                            completedTasksHistoryNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.completedTasksHistoryNoDataFoundMessage',
                            completedTasksNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.completedTasksNoDataFoundMessage',
                            createNewViews: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.createNewViews',
                            customize: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.customize',
                            customTitlePlaceholder: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.customTitlePlaceholder',
                            date: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.date',
                            default: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.default',
                            delayedTasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.delayedTasks',
                            dontUse: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.dontUse',
                            dynamic: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.dynamic',
                            editMode: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.editMode',
                            filters: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.filters',
                            lateBy: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.lateBy',
                            noDataFound: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.noDataFound',
                            onTimeTasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.onTimeTasks',
                            openTasksByQueueNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.openTasksByQueueNoDataFoundMessage',
                            overdueTasksNoDataFoundMessage: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.overdueTasksNoDataFoundMessage',
                            preview: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.preview',
                            priorities: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.priorities',
                            priority: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.priority',
                            properties: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.properties',
                            queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.queue',
                            queues: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.queues',
                            settings: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.settings',
                            sharedWith: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.sharedWith',
                            standardViewCantCustomize: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.standardViewCantCustomize',
                            static: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.static',
                            tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.tasks',
                            taskTypes: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.taskTypes',
                            text: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.text',
                            title: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.title',
                            total: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.total',
                            totalTasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.totalTasks',
                            type: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.type',
                            unsavedChangesDetected: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.unsavedChangesDetected',
                            users: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.users',
                        },
                        title: 'v2translations.schooldrive.modules.tasks.pages.dashboard.title',
                    },
                    myTasks: {
                        actions: {
                            nextTask: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.myTasks.actions.nextTask.label',
                            },
                        },
                        labels: {
                            finishedTodayCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.finishedTodayCount',
                            goodJob: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.goodJob',
                            inQueueCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.inQueueCount',
                            noTasksFoundInYourQueue: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.noTasksFoundInYourQueue',
                            queues: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.queues',
                            tasks: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.tasks',
                            updatedTodayCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.updatedTodayCount',
                        },
                        title: 'v2translations.schooldrive.modules.tasks.pages.myTasks.title',
                    },
                },
                title: 'v2translations.schooldrive.modules.tasks.title',
            },
        },
        pages: {
            dashboards: {
                title: 'v2translations.schooldrive.pages.dashboards.title',
            },
        },
        title: 'v2translations.schooldrive.title',
    },
};
