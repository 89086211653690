import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageScrollService {
    private readonly scrolling = new Subject<void>();
    public scrolling$ = this.scrolling.asObservable();
    private readonly renderer: Renderer2;
    private removeWheelListener?: () => void;

    constructor(protected rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    disableScroll(): void {
        const drawerContent = document.querySelector('.mat-drawer-content') as HTMLElement;

        this.removeWheelListener = this.renderer.listen(drawerContent, 'wheel', (event: WheelEvent) => {
            event.preventDefault();
        });
    }

    enableScroll(): void {
        if (this.removeWheelListener) {
            this.removeWheelListener();
            this.removeWheelListener = undefined;
        }
    }

    onScroll() {
        this.scrolling.next();
    }
}
