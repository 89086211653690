import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, fromEvent, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private componentWidth = new BehaviorSubject<number>(300);
    public componentWidth$ = this.componentWidth.asObservable();
    constructor(private destroyRef: DestroyRef) {}

    listenScreenWidth() {
        fromEvent(window, 'resize')
            .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100), distinctUntilChanged())
            .subscribe(() => this.calculateComponentWidth());
        this.calculateComponentWidth();
    }

    private calculateComponentWidth() {
        const width = window.innerWidth;

        if (width <= 767) {
            this.componentWidth.next(250);
        } else if (width >= 768 && width <= 1200) {
            this.componentWidth.next(260);
        } else if (width >= 1200 && width <= 1399) {
            this.componentWidth.next(245);
        } else if (width >= 1400 && width <= 1599) {
            this.componentWidth.next(280);
        } else if (width > 1600) {
            this.componentWidth.next(300);
        }
    }

    get getComponentWidth$() {
        return this.componentWidth$;
    }
}
