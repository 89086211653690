import { Injectable } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { WebsocketService } from '../../websocket/services/websocket.service';
import { HttpClient } from '@angular/common/http';
import {
    Notifications,
    NotificationsAccounting,
    NotificationsCustomers,
    NotificationsEmailSms,
    NotificationsHome,
    NotificationsResponse,
    NotificationsResponseAccounting,
    NotificationsResponseCustomer,
    NotificationsResponseEmailSms,
    NotificationsResponseHome,
    DropdownNotifications,
} from '../types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    blankValues: Notifications = {
        accounting: 0,
        accountingOperations: 0,
        accountingOperationsPayorder: 0,
        accountingOperationsPayorderApproved: 0,
        accountingOperationsPayorderExported: 0,
        accountingOperationsPayorderPending: 0,
        accountingOperationsPayreq: 0,
        accountingOperationsPayreqPending: 0,
        accountingOperationsSales: 0,
        accountingOperationsSalesUnidentifiedPayments: 0,
        accountingOperationsSalesUninvoicedItems: 0,
        customers: 0,
        customersSimilarCustomers: 0,
        emailSmsAutoresponderHistoryErrors: 0,
        emailSmsAutoresponderModerationMessages: 0,
        emailSmsAutoresponder: 0,
        emailSms: 0,
        home: 0,
        homePendingTasks: 0,
    };

    private notifications = new BehaviorSubject<Notifications>(this.blankValues);
    private dropdownNotifications = new BehaviorSubject<DropdownNotifications[]>([]);

    constructor(public authService: AuthService, public ws: WebsocketService, public http: HttpClient) {}

    get notifications$() {
        return this.notifications.asObservable();
    }

    get dropdownNotifications$() {
        return this.dropdownNotifications.asObservable();
    }

    public listen() {
        this.authService.currentSession.subscribe(data => {
            const channel = 'notifications-user-' + data.id;

            if (data.websocket) {
                this.ws.createConnection(data.websocket);
            }

            window['Echo']
                .private(channel)
                .on('pusher:subscription_succeeded', () => this.forceNotificationUpdate())
                .listen('.App\\Modules\\Schooldrive\\Modules\\Legacy\\Events\\UpdatedNotifications', e => {
                    const notificationsResponse = e.notifications as NotificationsResponse;

                    const accounting = this.mapAccountingNotifications(notificationsResponse.accounting);
                    const customer = this.mapCustomersNotifications(notificationsResponse.customers);
                    const home = this.mapHomeNotifications(notificationsResponse.home);
                    const emailSms = this.mapEmailSmsNotifications(notificationsResponse.email_sms);

                    const notifications: Notifications = {
                        ...accounting,
                        ...customer,
                        ...home,
                        ...emailSms,
                    };

                    this.notifications.next(notifications);
                    this.projectLegacyNotificationsToDropDown(notifications);

                    /* sd1 pages */
                    window['notifications'] = notifications;
                });
        });
    }

    private projectLegacyNotificationsToDropDown(notifications: Notifications) {
        const includeNotifications = [
            'accountingOperationsPayorder',
            'accountingOperationsPayreq',
            'accountingOperationsSalesUnidentifiedPayments',
            'accountingOperationsSalesUninvoicedItems',
            'customersSimilarCustomers',
            'emailSmsAutoresponderHistoryErrors',
            'emailSmsAutoresponderModerationMessages',
        ];

        const includeNotificationsDetails = {
            accountingOperationsPayorder: {
                state: 'app.accounting.payment_order',
                urlParam: 'accounting-operations-payorder',
                icon: 'fi-rr-dollar',
            },
            accountingOperationsPayreq: {
                state: 'app.accounting.paymentRequisition',
                urlParam: 'accounting-operations-payreq',
                icon: 'fi-rr-dollar',
            },
            accountingOperationsSalesUnidentifiedPayments: {
                state: 'app.accounting.sales.receivedpays.unidentifiedpays',
                urlParam: 'accounting-operations-sales-unidentified-payments',
                icon: 'fi-rr-dollar',
            },
            accountingOperationsSalesUninvoicedItems: {
                state: 'app.accounting.sales.invoices.pending',
                urlParam: 'accounting-operations-sales-uninvoiced-items',
                icon: 'fi-rr-dollar',
            },
            customersSimilarCustomers: {
                state: 'app.list_customer.similar_customers',
                urlParam: 'customers-similar-customers',
                icon: 'fi-rr-user',
            },
            emailSmsAutoresponderHistoryErrors: {
                state: 'app.mailbox.history',
                urlParam: 'email-sms-autoresponder-history-errors',
                icon: 'fi-rr-envelope',
            },
            emailSmsAutoresponderModerationMessages: {
                state: 'app.mailbox.moderation',
                urlParam: 'email-sms-autoresponder-moderation-messages',
                icon: 'fi-rr-envelope',
            },
        };

        const dropdownItems: DropdownNotifications[] = [];

        Object.keys(notifications).forEach(key => {
            if (includeNotifications.includes(key) && notifications[key] > 0) {
                const notificationQuantity = notifications[key];

                let notificationTranslate = '';
                if (notificationQuantity === 1) {
                    notificationTranslate = `${key}Singular`;
                } else {
                    notificationTranslate = `${key}Plural`;
                }

                const notificationDetails = includeNotificationsDetails[key];
                const notificationState = notificationDetails.state || '';
                const notificationUlParam = notificationDetails.urlParam || '';
                const notificationIcon = notificationDetails.icon || '';

                dropdownItems.push({
                    notificationType: 'schooldrive',
                    notificationTranslate: notificationTranslate,
                    notificationQuantity: notificationQuantity,
                    notificationState: notificationState,
                    notificationUlParam: notificationUlParam,
                    notificationIcon: notificationIcon,
                });
            }
        });

        this.dropdownNotifications.next(dropdownItems);
    }

    public forceNotificationUpdate() {
        this.http.get('/api/schooldrive/legacy/api/force-notifications-update').subscribe(() => {
            console.log('Requesting notifications update after connection');
        });
    }

    private mapAccountingNotifications(accounting: NotificationsResponseAccounting): NotificationsAccounting {
        const salesTotal = (accounting?.sales?.unidentifiedPayments ?? 0) + (accounting?.sales?.uninvoicedItems ?? 0);
        const payreqTotal = accounting?.payreq?.pending ?? 0;
        const payorderTotal =
            (accounting?.payorder?.pending ?? 0) +
            (accounting?.payorder?.approved ?? 0) +
            (accounting?.payorder?.exported ?? 0);

        const accountingOperationsTotal = salesTotal + payreqTotal + payorderTotal;
        const accountingTotal = accountingOperationsTotal;

        return {
            accounting: accountingTotal,
            accountingOperations: accountingOperationsTotal,
            accountingOperationsPayorder: payorderTotal,
            accountingOperationsPayorderPending: accounting?.payorder?.pending ?? 0,
            accountingOperationsPayorderApproved: accounting?.payorder?.approved ?? 0,
            accountingOperationsPayorderExported: accounting?.payorder?.exported ?? 0,
            accountingOperationsSales: salesTotal,
            accountingOperationsSalesUnidentifiedPayments: accounting?.sales?.unidentifiedPayments ?? 0,
            accountingOperationsSalesUninvoicedItems: accounting?.sales?.uninvoicedItems ?? 0,
            accountingOperationsPayreq: payreqTotal,
            accountingOperationsPayreqPending: accounting?.payreq?.pending ?? 0,
        };
    }

    private mapCustomersNotifications(customer: NotificationsResponseCustomer): NotificationsCustomers {
        const similarCustomers = customer?.similar_customers ?? 0;
        const customerTotal = similarCustomers;

        return {
            customers: customerTotal,
            customersSimilarCustomers: similarCustomers,
        };
    }

    private mapHomeNotifications(home: NotificationsResponseHome): NotificationsHome {
        const tasksTotal = home?.tasks?.pending ?? 0;

        const total = tasksTotal;

        return {
            home: total,
            homePendingTasks: home?.tasks?.pending ?? 0,
        };
    }

    private mapEmailSmsNotifications(emailSms: NotificationsResponseEmailSms): NotificationsEmailSms {
        const autorespondersTotal = (emailSms?.history?.errors ?? 0) + (emailSms?.moderation?.messages ?? 0);

        const total = autorespondersTotal;

        return {
            emailSms: total,
            emailSmsAutoresponder: autorespondersTotal,
            emailSmsAutoresponderHistoryErrors: emailSms?.history?.errors ?? 0,
            emailSmsAutoresponderModerationMessages: emailSms?.moderation?.messages ?? 0,
        };
    }
}
